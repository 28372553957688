import Vue from "vue";
import axios from "@/plugins/axios";
import configs from "@/configs/configs";

export default {
  call(method, apiModule, systemModule, route, routeMethod, payload) {
    const data = this.setData(systemModule, payload);
    const url = this.setURL(apiModule, route, routeMethod);

    return axios({
      method,
      url,
      data,
    });
  },

  setData(systemModule, payload) {
    if (payload instanceof FormData) {
      payload.append("clientId", configs.clientId);
      payload.append("system", configs.system);
      payload.append("language", configs.language);
      payload.append("module", systemModule);
      return payload;
    }

    return {
      ...payload,
      clientId: configs.clientId,
      system: configs.system,
      language: configs.language,
      module: systemModule,
    };
  },

  setURL(apiModule = '', route = '', routeMethod ='') {
    if (configs.env === "prod") {
      //return `https://leads.api.${apiModule}.znaptech.com/${route}${routeMethod}`;
      return `https://api-leads-prod01.znaptech.com/${apiModule.toString().toLowerCase().replaceAll('.', '')}Service/${route}${routeMethod}`;
    }

    if (configs.env === "homol") {
      return `https://api-leads-homol.znaptech.com/${apiModule.toString().toLowerCase().replaceAll('.', '')}Service/${route}${routeMethod}`;
    }

    if (configs.env === "dev") {
      return `https://leads.api.${apiModule}.znaptech.com/${route}${routeMethod}`;
    }

    if (configs.env === "local") {
      const apiPort = this.getApiPort(apiModule);

      return `http://localhost:${apiPort}/${route}${routeMethod}`;
    }
  },

  getApiPort(apiModule) {
    if (apiModule === "master.data") {
      return "9014";
    }

    if (apiModule === "user") {
      return "9000";
    }

    if (apiModule === "client") {
      return "9001";
    }
    if (apiModule === "leads") {
      return "9016";
    }

    if (apiModule === "approval.flow") {
      return "9015";
    }

    if (apiModule === "security") {
      return "9002";
    }

    if (apiModule === "unit") {
      return "9008";
    }

    if (apiModule === "event") {
      return "9013";
    }
  },

  handleError(error) {
    if (error.response && error.response.status === 500) {
      Vue.prototype.$toast.error(`Erro inesperado`);
      return;
    }

    if (error.response && error.response.data) {
      const { msg } = error.response.data;
      Vue.prototype.$toast.error(msg);
      return;
    }

    if (error?.response?.data?.message?.includes("Duplicate entry")) {
      const message = error.response.data.message;
      const regex = /'([^']+)'/;
      const correspondence = regex.exec(message);

      const data = correspondence[1];
      Vue.prototype.$toast.error(`Dado ${data} já existe`);
      return;
    }

    if (error.name === "AxiosError") {
      Vue.prototype.$toast.error(`Erro inesperado`);
      return;
    }
  },
};
